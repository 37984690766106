/// <reference path="../common/breakpoints.scss" />
/// <reference path="../common/colours.scss" />

.previous-events {
    ul {
        list-style-type: none;
        padding-left: 0;

        .previous-events-year {
            border-bottom: 1px solid $pink;
            font-size: 32px;
            font-weight: 600;
            padding-top: 12px;
            margin-bottom: 12px;
        }

        li {
            .previous-events-date {
                font-size: 22px;
                font-weight: 400;
                display: inline-block;
                text-align: center;
            }
            padding-bottom: 12px;
        }
    }
}
