/// <reference path="../common/breakpoints.scss" />
/// <reference path="../common/colours.scss" />

.blog {
    .blog-post {
        margin-top: 16px;
        padding-bottom: 28px;
        margin-bottom: 40px;
        border-bottom: 1px solid $pink;

        h1 {
            font-size: 24px;
            font-weight: 600;
        }

        .blog-post-date {
            color: $dark-grey;
            font-weight: 600;
            padding-bottom: 8px;
        }
    }
}
