$pink: rgb(227, 72, 174);
$green: rgb(72, 227, 208);
$blue: rgb(0, 128, 221);
$text-color: #100F00;
$grey: #808080;
$light-grey: #F5F5F5;
$dark-grey: #404040;

$gradient-colour-from: lighten($pink, 12%);
$gradient-colour-to: lighten($blue, 35%);
