/// <reference path="../common/breakpoints.scss" />
/// <reference path="../common/colours.scss" />

.home {
    padding-top: 20px;
    margin-right: -15px;
    margin-left: -15px;

    @include media-breakpoint-up(sm) {
        padding-top: 80px;
    }

    .hero {
        .main-text {

            h1 {
                font-size: 32px;
                line-height: 38px;
                font-weight: 700;
                margin-bottom: 0px;

                @include media-breakpoint-up(sm) {
                    font-size: 44px;
                    line-height: 58px;
                }
            }

            .subtext {
                margin-top: 24px;
                margin-bottom: 32px;

                p {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 400;
                    margin-bottom: 16px;
                }
            }

            .follow-us {
                display: flex;
                align-items: center;


                header {
                    font-size: 20px;
                    font-weight: 700;
                }

                img {
                    width: 36px;
                    margin-left: 12px;
                    border-radius: 6px;
                }
            }
        }

        .upcoming-events-overlay {
            z-index: 100;
            padding: 0;
        }

        .upcoming-events {
            z-index: 100;
            background: linear-gradient(180deg, rgba(0, 128, 221, 0.1) 0%, rgba(196, 196, 196, 0.5) 100%);
            border-radius: 17px;
            margin-top: 32px;
            padding-bottom: 100px;

            @include media-breakpoint-up(sm) {
                margin-top: 0px;
                padding-bottom: 200px;
                margin-right: 20px;
            }

            h1 {
                padding-top: 32px;
                font-size: 28px;
                font-weight: 700;
            }

            .event {
                background-color: white;
                display: flex;
                flex-direction: row;
                margin-top: 28px;
                margin-left: 20px;
                margin-right: 20px;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);



                .event-link {
                    display: flex;
                    flex-direction: row;
                    color: $text-color;
                    text-decoration: none;
                }

                .event-date-time {
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    min-width: 80px;
                    min-height: 360px;

                    @include media-breakpoint-up(sm) {
                        min-height: 160px;
                    }

                    .event-date {
                        font-weight: 300;
                        font-size: 48px;
                        line-height: 50px;
                    }

                    .event-month {
                        font-weight: 600;
                        font-size: 26px;
                        text-transform: uppercase;
                    }
                }

                .event-date-time--pink {
                    background-color: $pink;
                }

                .event-date-time--green {
                    background-color: $green;
                }

                .event-details {
                    margin: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    @include media-breakpoint-up(sm) {
                    }

                    .event-name {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 32px;
                        text-transform: uppercase;

                        i {
                            color: $blue;
                            font-style: normal;
                        }
                    }

                    .event-location {
                        font-weight: 600;
                        font-size: 18px;
                    }

                    .event-description {
                        font-weight: 400;
                        font-size: 15px;
                        padding-top: 4px;

                        p{
                            margin-bottom: 4px;
                        }
                    }

                    code {
                        font-size: 100%;
                        color: $pink;
                        word-break: break-word;
                    }

                    .event-link-button {
                        text-transform: uppercase;
                        color: white;
                        border-radius: 32px;
                        background: lighten($blue, 20%);
                        padding: 4px;
                        font-size: 14px;
                        line-height: 16px;
                        display: block;
                        width: 120px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .nomadic {
        z-index: 1;
        transform: translateY(-60px);
        margin-bottom: -60px;
        padding-top: 80px;
        background: radial-gradient(400px at 0% 15%, $gradient-colour-from 0%, $gradient-colour-to 100%);
        -webkit-mask-image: url('/images/mask.png'), linear-gradient(rgba(0, 0, 0, 0.0), transparent);
        mask-image: url('/images/mask.png'), linear-gradient(rgba(0, 0, 0, 0.0), transparent);
        -webkit-mask-repeat: no-repeat;

        @include media-breakpoint-up(sm) {
            transform: translateY(-160px);
            margin-bottom: -160px;
            height: 680px;
            padding-top: 200px;
            background: radial-gradient(1000px at 0% 15%, $gradient-colour-from 0%, $gradient-colour-to 100%);
            -webkit-mask-image: url('/images/mask-big.png'), linear-gradient(rgba(0, 0, 0, 0.0), transparent);
            mask-image: url('/images/mask.png-big'), linear-gradient(rgba(0, 0, 0, 0.0), transparent);
        }

        .devices {

            @include media-breakpoint-up(sm) {
                transform: translateY(-100px);
            }
        }

        h1 {
            font-weight: 300;
            font-size: 30px;
            text-transform: uppercase;
            line-height: 28px;
            margin-bottom: 0px;
        }

        p {
            color: $text-color;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 34px;
        }
    }

    .event-types {
        h1 {
            font-size: 42px;
            font-weight: 400;
        }

        h2 {
            padding-top: 16px;
            font-size: 24px;
            font-weight: 600;
        }

        .event-type-image {
            width: 200px;
            border-radius: 50px;
        }

        .event-type-description {
            font-size: 15px;
        }

        .event-type-more-info {
            display: block;
            padding-top: 8px;
            font-size: 15px;
        }
    }

    .code-shapes {
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-up(sm) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        %code-shape {
            color: $pink;
            font-size: 48px;
            font-weight: 400;
            display: inline-block;

            @include media-breakpoint-up(sm) {
                font-size: 60px;
                line-height: 80px;
            }

            @include media-breakpoint-up(lg) {
                line-height: 120px;
                font-size: 100px;
            }
        }

        .code-shape-1 {
            @extend %code-shape;
            transform: rotate(-15deg);
        }

        .code-shape-2 {
            @extend %code-shape;
            color: $blue;
            transform: rotate(20deg);
        }

        .code-shape-3 {
            @extend %code-shape;
            color: $green;
            transform: rotate(80deg);
        }

        .code-shape-4 {
            @extend %code-shape;
            transform: rotate(-45deg);
        }

        .code-shape-5 {
            @extend %code-shape;
            color: $blue;
            transform: rotate(37deg);
        }

        .code-shape-6 {
            @extend %code-shape;
            color: $green;
            transform: rotate(37deg);
        }

        .code-shape-7 {
            @extend %code-shape;
            transform: rotate(-24deg);
        }

        .code-shape-8 {
            @extend %code-shape;
            color: $blue;
            transform: rotate(79deg);
        }

        .code-shape-9 {
            @extend %code-shape;
            color: $green;
            transform: rotate(-14deg);
        }

        .code-shape-10 {
            @extend %code-shape;
            transform: rotate(94deg);
        }
    }
}
