/// <reference path="../common/breakpoints.scss" />
/// <reference path="../common/colours.scss" />

nav {
    background-color: white;

    .navbar-brand {
        font-weight: 700;
        font-size: 24px;
        color: black;

        img {
            margin-top: -11px;
        }

        @include media-breakpoint-up(sm) {
            font-size: 28px;
        }
    }

    .navbar-nav {
        padding-top: 8px;

        .nav-item {
            text-align: right;
        }

        .nav-link {
            font-size: 16px;
            font-weight: 600;
            margin-left: 16px;
            color: $dark-grey !important;

            @include media-breakpoint-up(sm) {
                margin-left: 48px;
            }

            &:hover {
                color: $grey;
            }
        }
    }
}
