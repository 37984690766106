/// <reference path="../common/breakpoints.scss" />
/// <reference path="../common/colours.scss" />

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 48px;


    .twitter-links {
        padding-bottom: 8px
    }

    .footer-links-mobile {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
