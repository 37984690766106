.who-are-we {
    .card {
        width:300px;
        background:#fff;
        box-shadow:0 20px 50px rgba(0,0,0,.1);
        border-radius:10px;
        transition:0.5s;

        &:hover {
            box-shadow:0 30px 70px rgba(0,0,0,.2);
        }

        .box {
            left:0;
            text-align:center;
            padding:20px;
            box-sizing:border-box;
            width:100%;

            .img {
                width:120px;
                height:120px;
                margin:0 auto;
                border-radius:50%;
                overflow:hidden;
                
                img {
                    width:100%;
                    height:100%
                }
            }

            h2 {
                font-size:20px;
                color:#262626;
                margin-top:20px auto;
                padding-top: 20px;
                span {
                    font-size:14px;
                    background:$pink;
                    color:#fff;
                    display:inline-block;
                    padding:4px 10px;
                    border-radius:15px;
                }
            }

            p {
                color:#262626;
            }

            span {
                display:inline-flex;
            }

            ul {
                margin:0;
                padding:0;

                li {
                    list-style:none;
                    float:left;

                    a {
                        display:block;
                        color:#aaa;
                        margin:0 10px;
                        font-size:20px;
                        transition:0.5s;
                        text-align:center;
                    }
                    &:hover {
                        color:#e91e63;
                        transform:rotateY(360deg);
                    }
                }
            }
        }
    }
}